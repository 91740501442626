<template>
  <ConfirmationModal :show="toggleModal" @close="closeDeleteHandler()">
    <template #title>Potwierdź </template>

    <template #content>
      Czy na pewno chcesz usunąć grupę podatkową<br />
      <strong>{{ taxGroupData.name }}</strong
      >?
    </template>

    <template #footer>
      <SecondaryButton @click="closeDeleteHandler()"> Anuluj </SecondaryButton>

      <DangerButton class="ml-2" @click="removeTaxGroupAction()">
        Usuń
      </DangerButton>
    </template>
  </ConfirmationModal>
</template>

<script>
import ConfirmationModal from '../../../components/@globals/helpers/ConfirmationModal';
import SecondaryButton from '../../../components/@globals/helpers/SecondaryButton';
import DangerButton from '../../../components/@globals/helpers/DangerButton';
import TaxGroupsService from '@/service/tax-groups/taxGroups.js';
import { ref, watch } from 'vue';
import { useStore } from 'vuex';
export default {
  name: 'TaxGroupsDelete',
  props: {
    taxGroupData: Object,
    urlParams: Object,
    modalOpen: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ConfirmationModal,
    SecondaryButton,
    DangerButton,
  },
  emits: ['closeDeleteChanges'],
  setup(props, { emit }) {
    const store = useStore();
    const toggleModal = ref(props.modalOpen);

    watch(
      () => props.modalOpen,
      (val) => {
        toggleModal.value = val;
      }
    );

    function closeDeleteHandler() {
      toggleModal.value = false;
      emit('closeDeleteChanges', false);
    }

    function removeTaxGroupAction() {
      TaxGroupsService.deleteTaxGroup(props.taxGroupData.id).then(
        () => {
          let silentConfig = { ...props.urlParams, silent: true };
          store.dispatch('getTaxGroups', silentConfig);
          store.commit('setAppNotifications', {
            title: 'Grupa podatkowa usunięta',
            content: 'Pomyslnie usunieto grupę podatkową',
            type: 'success',
            autoclose: true,
          });
          closeDeleteHandler();
        },
        () => {
          store.commit('setAppNotifications', {
            title: 'Nie udało się usunąć grupy podatkowej',
            content: '',
            type: 'error',
            autoclose: true,
          });
          closeDeleteHandler();
        }
      );
    }

    return {
      toggleModal,
      removeTaxGroupAction,
      closeDeleteHandler,
    };
  },
};
</script>

<style></style>
