<template>
  <div>
    <SectionHeading
      :title="$t('views.catalog.attributes.title')"
      iconName="TagIcon"
      subTitle="Manage attributes of your products"
    />

    <!-- ACTIONS -->
    <div class="mt-10 flex justify-start flex-wrap items-center">
      <SecondaryButton @click="refreshData">
        <DynamicIconSolid
          icon="RefreshIcon"
          class="flex-shrink-0 h-5 w-5 mr-2 text-gray-700"
          aria-hidden="true"
        />
        Refresh Data
      </SecondaryButton>

      <Label value="Typ" class="m-2" />
      <TRichSelect
        v-model="attributeTypeFilter"
        :options="attributeTypeList"
        valueAttribute="value"
        textAttribute="label"
        placeholder="Choose"
        multiple
        class="w-1/12"
      />

      <div class="justify-self-end ml-auto mr-0 flex align-center space-x-4">
        <router-link
          :to="{
            name: 'AttributesAddEdit',
            params: { id: 'new' },
          }"
        >
          <Button>
            <DynamicIconSolid
              icon="PlusIcon"
              class="mr-2 -ml-1 h-5 w-5"
              aria-hidden="true"
            />
            New Attribute
          </Button>
        </router-link>

        <Select
          label="Per page"
          :dataList="itemsPerPageArray"
          @valueChanges="perPageHandler($event)"
        />
      </div>
    </div>
    <!-- ATTRIBUTES DATA -->
    <div class="flex flex-col sm:-mx-6 lg:-mx-8">
      <div class="my-2 overflow-x-auto">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="
              shadow
              overflow-hidden
              border-b border-gray-200
              sm:rounded-lg
              relative
            "
          >
            <LoadingSpinner v-if="attributesData.loading" />
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="customers-list__col flex items-center">
                    <div class="flex items-center">
                      <span>ID</span>
                      <Sort
                        keyValue="id"
                        @valueChanges="sortHandler($event)"
                        :currentSort="currentSort == 'id'"
                      />
                    </div>
                  </th>
                  <th scope="col" class="customers-list__col">
                    <div class="flex items-center">
                      <span>Nazwa</span>
                      <Sort
                        keyValue="name"
                        @valueChanges="sortHandler($event)"
                        :currentSort="currentSort == 'name'"
                      />
                    </div>
                  </th>
                  <th scope="col" class="customers-list__col">
                    <div class="flex items-center">
                      <span>Identyfikator</span>
                      <Sort
                        keyValue="identifier"
                        @valueChanges="sortHandler($event)"
                        :currentSort="currentSort == 'identifier'"
                      />
                    </div>
                  </th>
                  <th scope="col" class="customers-list__col">
                    <div class="flex items-center">
                      <span>Typ</span>
                      <Sort
                        keyValue="type"
                        @valueChanges="sortHandler($event)"
                        :currentSort="currentSort == 'type'"
                      />
                    </div>
                  </th>
                  <th scope="col" class="customers-list__col">
                    <div class="flex items-center">
                      <span>Tłumaczalny</span>
                    </div>
                  </th>
                  <th scope="col" class="customers-list__col">
                    <div class="flex items-center">
                      <span>Data utworzenia</span>
                      <Sort
                        keyValue="created_at"
                        @valueChanges="sortHandler($event)"
                        :currentSort="currentSort == 'created_at'"
                      />
                    </div>
                  </th>
                  <th scope="col" class="customers-list__col">
                    <div class="flex items-center">
                      <span>Data modyfikacji</span>
                      <Sort
                        keyValue="updated_at"
                        @valueChanges="sortHandler($event)"
                        :currentSort="currentSort == 'updated_at'"
                      />
                    </div>
                  </th>
                  <th scope="col" class="relative customers-list__col"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in attributesData.data"
                  :key="item.id"
                  :class="index % 2 ? 'bg-white' : 'bg-gray-50'"
                >
                  <td
                    class="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-sm
                      font-medium
                      text-gray-900
                    "
                  >
                    {{ item.id }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ item.name }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ item.identifier }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <span
                      class="
                        inline-flex
                        items-center
                        px-2
                        py-0.5
                        rounded
                        text-xs
                        font-medium
                        bg-gray-100
                        text-gray-800
                        mr-1
                        capitalize
                      "
                    >
                      {{ item.type }}
                    </span>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <span
                      :class="[
                        item.is_translatable
                          ? 'bg-green-100 text-green-800'
                          : 'bg-red-100 text-red-800',
                      ]"
                      class="
                        inline-flex
                        items-center
                        px-2
                        py-0.5
                        rounded
                        text-xs
                        font-medium
                        mr-1
                      "
                    >
                      {{ item.is_translatable ? 'Tak' : 'Nie' }}
                    </span>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{
                      dayjs
                        .utc(item.created_at)
                        .local()
                        .format('DD/MM/YYYY  HH:mm')
                    }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{
                      dayjs
                        .utc(item.updated_at)
                        .local()
                        .format('DD/MM/YYYY  HH:mm')
                    }}
                  </td>
                  <td
                    class="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-center text-sm text-gray-500
                    "
                  >
                    <div class="flex space-x-6 justify-end">
                      <router-link
                        :to="{
                          name: 'AttributesAddEdit',
                          params: { id: item.id },
                        }"
                        class="text-indigo-600 hover:text-indigo-900"
                      >
                        <DynamicIconSolid
                          icon="PencilIcon"
                          class="h-5 w-5 text-gray-400 m-auto cursor-pointer"
                          aria-hidden="true"
                        />
                      </router-link>
                      <DynamicIconSolid
                        icon="TrashIcon"
                        class="h-5 w-5 text-gray-400 cursor-pointer"
                        aria-hidden="true"
                        @click="removeAttribute(item)"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- PAGINATION -->
    <Pagination @changePage="changePage($event)" :meta="attributesData.meta" />
    <!-- DELETE ATTRIBUTE -->
    <AttributesDelete
      :modalOpen="removeAttributeModal"
      :attributeData="attributeToRemove"
      :urlParams="config"
      @closeDeleteChanges="removeAttributeModal = false"
    />
  </div>
</template>

<script>
import Select from '@/components/select/Select';
import Pagination from '@/components/@globals/helpers/Pagination';
import Sort from '@/components/table/Sort';
import LoadingSpinner from '@/components/@globals/helpers/LoadingSpinner';
import { useStore } from 'vuex';
import { computed, reactive, ref, getCurrentInstance, watch } from 'vue';
import AttributesDelete from './AttributesDelete.vue';
import { ATTRIBUTE_TYPE_LIST } from './attribute-constants';
import SecondaryButton from '../../../components/@globals/helpers/SecondaryButton';
import Button from '../../../components/@globals/helpers/Button';
import Label from '../../../components/@globals/helpers/Label';
import { TRichSelect } from '@variantjs/vue';
export default {
  name: 'AttributesList',
  components: {
    Button,
    SecondaryButton,
    Pagination,
    LoadingSpinner,
    Select,
    Sort,
    AttributesDelete,
    TRichSelect,
    Label,
  },
  setup() {
    const instance = getCurrentInstance();
    const dayjs = instance.appContext.config.globalProperties.$dayjs;
    const currentSort = ref('');
    const store = useStore();
    let config = reactive({
      page: 1,
      per_page: 5,
      silent: false,
    });
    const itemsPerPageArray = [
      { id: 1, value: 5 },
      { id: 2, value: 10 },
      { id: 3, value: 15 },
      { id: 4, value: 20 },
    ];

    const attributeTypeList = [...ATTRIBUTE_TYPE_LIST];
    const attributeTypeFilter = ref([]);

    watch(
      () => attributeTypeFilter,
      (currentValue) => {
        attributeTypeHandler(currentValue.value);
      },
      { deep: true }
    );

    store.dispatch('getAttributes', config);

    const attributesData = computed(() => store.getters.getAttributes);

    function refreshData() {
      store.dispatch('getAttributes', {
        page: 1,
        per_page: 5,
      });
    }
    function changePage(page) {
      config.page = page;
      store.dispatch('getAttributes', config);
    }
    function perPageHandler(payload) {
      config.per_page = payload;
      store.dispatch('getAttributes', config);
    }
    function attributeTypeHandler(payload) {
      if (payload.length) {
        config = { ...config, type: payload };
      } else {
        delete config.type;
      }
      store.dispatch('getAttributes', config);
    }

    function sortHandler(payload) {
      currentSort.value = payload.key;
      if (payload.sort != null) {
        config = { ...config, sort: payload.sort };
      } else {
        delete config.sort;
      }
      store.dispatch('getAttributes', config);
    }

    const removeAttributeModal = ref(false);
    const attributeToRemove = ref(null);
    function removeAttribute(item) {
      attributeToRemove.value = item;
      removeAttributeModal.value = true;
    }

    return {
      attributesData,
      itemsPerPageArray,
      attributeTypeList,
      currentSort,
      removeAttributeModal,
      refreshData,
      changePage,
      perPageHandler,
      sortHandler,
      removeAttribute,
      attributeToRemove,
      config,
      dayjs,
      attributeTypeFilter,
    };
  },
};
</script>
