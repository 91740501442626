<template>
  <div>
    <SectionHeading
      :title="$t('views.configuration.tax_groups.title')"
      iconName="ReceiptTaxIcon"
      subTitle="Manage taxation of your products"
    />

    <!-- ACTIONS -->
    <div class="mt-10 flex justify-start flex-wrap items-center">
      <SecondaryButton @click="refreshData">
        <DynamicIconSolid
          icon="RefreshIcon"
          class="flex-shrink-0 h-5 w-5 mr-2 text-gray-700"
          aria-hidden="true"
        />
        Refresh Data
      </SecondaryButton>

      <Label value="Stawka VAT" class="m-2" />
      <TRichSelect
        v-model="taxRatesFilter"
        :options="taxRates.data"
        valueAttribute="rate"
        textAttribute="rate"
        placeholder="Choose"
        multiple
        class="w-1/12"
      />

      <div class="justify-self-end ml-auto mr-0 flex align-center space-x-4">
        <router-link
          :to="{
            name: 'TaxGroupsAddEdit',
            params: { id: 'new' },
          }"
        >
          <Button>
            <DynamicIconSolid
              icon="PlusIcon"
              class="mr-2 -ml-1 h-5 w-5"
              aria-hidden="true"
            />
            New Tax Group
          </Button>
        </router-link>
        <Select
          label="Per page"
          :dataList="itemsPerPageArray"
          @valueChanges="perPageHandler($event)"
        />
      </div>
    </div>

    <!-- TAX GROUPS DATA -->
    <div class="flex flex-col sm:-mx-6 lg:-mx-8">
      <div class="my-2 overflow-x-auto">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="
              shadow
              overflow-hidden
              border-b border-gray-200
              sm:rounded-lg
              relative
            "
          >
            <LoadingSpinner v-if="taxGroupsData.loading" />
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="customers-list__col flex items-center">
                    <div class="flex items-center">
                      <span>ID</span>
                      <Sort
                        keyValue="id"
                        @valueChanges="sortHandler($event)"
                        :currentSort="currentSort == 'id'"
                      />
                    </div>
                  </th>
                  <th scope="col" class="customers-list__col">
                    <div class="flex items-center">
                      <span>Nazwa</span>
                      <Sort
                        keyValue="name"
                        @valueChanges="sortHandler($event)"
                        :currentSort="currentSort == 'name'"
                      />
                    </div>
                  </th>
                  <th scope="col" class="customers-list__col">
                    <div class="flex items-center">
                      <span>Identyfikator</span>
                      <Sort
                        keyValue="identifier"
                        @valueChanges="sortHandler($event)"
                        :currentSort="currentSort == 'identifier'"
                      />
                    </div>
                  </th>
                  <th scope="col" class="customers-list__col">
                    <div class="flex items-center">
                      <span>Stawka VAT</span>
                      <Sort
                        keyValue="tax_rate_id"
                        @valueChanges="sortHandler($event)"
                        :currentSort="currentSort == 'tax_rate_id'"
                      />
                    </div>
                  </th>
                  <th scope="col" class="customers-list__col">
                    <div class="flex items-center">
                      <span>Data utworzenia</span>
                      <Sort
                        keyValue="created_at"
                        @valueChanges="sortHandler($event)"
                        :currentSort="currentSort == 'created_at'"
                      />
                    </div>
                  </th>
                  <th scope="col" class="customers-list__col">
                    <div class="flex items-center">
                      <span>Data modyfikacji</span>
                      <Sort
                        keyValue="updated_at"
                        @valueChanges="sortHandler($event)"
                        :currentSort="currentSort == 'updated_at'"
                      />
                    </div>
                  </th>
                  <th scope="col" class="relative customers-list__col"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in taxGroupsData.data"
                  :key="item.id"
                  :class="index % 2 ? 'bg-white' : 'bg-gray-50'"
                >
                  <td
                    class="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-sm
                      font-medium
                      text-gray-900
                    "
                  >
                    {{ item.id }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ item.name }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ item.identifier }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ item.tax_rate.rate }} %
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{
                      dayjs
                        .utc(item.created_at)
                        .local()
                        .format('DD/MM/YYYY  HH:mm')
                    }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{
                      dayjs
                        .utc(item.updated_at)
                        .local()
                        .format('DD/MM/YYYY  HH:mm')
                    }}
                  </td>

                  <td
                    class="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-center text-sm text-gray-500
                    "
                  >
                    <div class="flex space-x-6 justify-end">
                      <router-link
                        :to="{
                          name: 'TaxGroupsAddEdit',
                          params: { id: item.id },
                        }"
                        class="text-indigo-600 hover:text-indigo-900"
                      >
                        <DynamicIconSolid
                          icon="PencilIcon"
                          class="h-5 w-5 text-gray-400 m-auto cursor-pointer"
                          aria-hidden="true"
                        />
                      </router-link>
                      <DynamicIconSolid
                        icon="TrashIcon"
                        class="h-5 w-5 text-gray-400 m-auto cursor-pointer"
                        aria-hidden="true"
                        @click="removeTaxGroup(item)"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- PAGINATION -->
    <Pagination @changePage="changePage($event)" :meta="taxGroupsData.meta" />

    <!-- DELETE TAX GROUP -->
    <TaxGroupsDelete
      :modalOpen="removeTaxGroupModal"
      :taxGroupData="taxGroupToRemove"
      :urlParams="config"
      @closeDeleteChanges="removeTaxGroupModal = false"
    />
  </div>
</template>

<script>
import { computed, reactive, ref, watch, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import Pagination from '@/components/@globals/helpers/Pagination';
import LoadingSpinner from '@/components/@globals/helpers/LoadingSpinner';
import Sort from '@/components/table/Sort';
import TaxGroupsDelete from './TaxGroupsDelete.vue';
import { TRichSelect } from '@variantjs/vue';
import Select from '@/components/select/Select';
import Label from '../../../components/@globals/helpers/Label';
import SecondaryButton from '../../../components/@globals/helpers/SecondaryButton';
import Button from '../../../components/@globals/helpers/Button';

export default {
  name: 'TaxGroupsList',

  components: {
    SecondaryButton,
    Pagination,
    LoadingSpinner,
    Sort,
    TaxGroupsDelete,
    TRichSelect,
    Select,
    Label,
    Button,
  },

  setup() {
    const instance = getCurrentInstance();
    const dayjs = instance.appContext.config.globalProperties.$dayjs;

    const currentSort = ref('');
    const store = useStore();
    let config = reactive({
      page: 1,
      per_page: 5,
      silent: false,
    });
    const itemsPerPageArray = [
      { id: 1, value: 5 },
      { id: 2, value: 10 },
      { id: 3, value: 15 },
      { id: 4, value: 20 },
    ];
    const taxRatesFilter = ref([]);

    store.dispatch('getTaxGroups', config);
    const taxGroupsData = computed(() => store.getters.getTaxGroups);

    store.dispatch('getTaxRates', {});
    const taxRates = computed(() => store.getters.getTaxRates);

    function refreshData() {
      store.dispatch('getTaxGroups', {
        page: 1,
        per_page: 5,
      });
    }

    function changePage(page) {
      config.page = page;
      store.dispatch('getTaxGroups', config);
    }

    function perPageHandler(payload) {
      config.per_page = payload;
      store.dispatch('getTaxGroups', config);
    }

    function taxRatesHandler(payload) {
      if (payload.length) {
        config = { ...config, rate: payload };
      } else {
        delete config.rate;
      }
      store.dispatch('getTaxGroups', config);
    }

    watch(
      () => taxRatesFilter,
      (currentValue) => {
        taxRatesHandler(currentValue.value);
      },
      { deep: true }
    );

    function sortHandler(payload) {
      currentSort.value = payload.key;
      if (payload.sort != null) {
        config = { ...config, sort: payload.sort };
      } else {
        delete config.sort;
      }
      store.dispatch('getTaxGroups', config);
    }

    const removeTaxGroupModal = ref(false);
    const taxGroupToRemove = ref(null);
    function removeTaxGroup(item) {
      taxGroupToRemove.value = item;
      removeTaxGroupModal.value = true;
    }

    return {
      taxGroupsData,
      currentSort,
      removeTaxGroupModal,
      taxGroupToRemove,
      changePage,
      sortHandler,
      removeTaxGroup,
      config,
      refreshData,
      taxRatesHandler,
      taxRates,
      taxRatesFilter,
      itemsPerPageArray,
      perPageHandler,
      dayjs,
    };
  },
};
</script>
