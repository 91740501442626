<template>
  <div>
    <SectionHeading
      :title="$t('views.employees.employees.title')"
      iconName="OfficeBuildingIcon"
      subTitle="Manage your employees"
    />

    <!-- ACTIONS -->
    <div class="mt-10 flex justify-start flex-wrap items-center">
      <SecondaryButton @click="refreshData">
        <DynamicIconSolid
          icon="RefreshIcon"
          class="flex-shrink-0 h-5 w-5 mr-2 text-gray-700"
          aria-hidden="true"
        />
        Refresh Data
      </SecondaryButton>

      <Label value="Roles" class="m-2" />
      <TRichSelect
        v-model="rolesFilter"
        :options="rolesList.data"
        valueAttribute="id"
        textAttribute="description"
        placeholder="Choose"
        multiple
        class="w-1/12"
      />

      <div class="justify-self-end ml-auto mr-0 flex align-center space-x-4">
        <router-link
          :to="{
            name: 'EmployeesAddEdit',
            params: { id: 'new' },
          }"
        >
          <Button>
            <DynamicIconSolid
              icon="PlusIcon"
              class="mr-2 -ml-1 h-5 w-5"
              aria-hidden="true"
            />
            New Employee
          </Button>
        </router-link>
        <Select
          label="Per page"
          :dataList="itemsPerPageArray"
          @valueChanges="perPageHandler($event)"
        />
      </div>
    </div>

    <!-- EMPLOYEES DATA -->
    <div class="flex flex-col sm:-mx-6 lg:-mx-8">
      <div class="my-2 overflow-x-auto">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="
              shadow
              overflow-hidden
              border-b border-gray-200
              sm:rounded-lg
              relative
            "
          >
            <LoadingSpinner v-if="employeesData.loading" />
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="customers-list__col flex items-center">
                    <div class="flex items-center">
                      <span>ID</span>
                      <Sort
                        keyValue="id"
                        @valueChanges="sortHandler($event)"
                        :currentSort="currentSort == 'id'"
                      />
                    </div>
                  </th>
                  <th scope="col" class="customers-list__col">
                    <div class="flex items-center">
                      <span>Imię</span>
                      <Sort
                        keyValue="first_name"
                        @valueChanges="sortHandler($event)"
                        :currentSort="currentSort == 'first_name'"
                      />
                    </div>
                  </th>
                  <th scope="col" class="customers-list__col">
                    <div class="flex items-center">
                      <span>Nazwisko</span>
                      <Sort
                        keyValue="last_name"
                        @valueChanges="sortHandler($event)"
                        :currentSort="currentSort == 'last_name'"
                      />
                    </div>
                  </th>
                  <th scope="col" class="customers-list__col">Email</th>
                  <th scope="col" class="customers-list__col">Telefon</th>
                  <th scope="col" class="customers-list__col">Role</th>
                  <th scope="col" class="customers-list__col">
                    <div class="flex items-center">
                      <span>Data utworzenia</span>
                      <Sort
                        keyValue="created_at"
                        @valueChanges="sortHandler($event)"
                        :currentSort="currentSort == 'created_at'"
                      />
                    </div>
                  </th>
                  <th scope="col" class="customers-list__col">
                    <div class="flex items-center">
                      <span>Data modyfikacji</span>
                      <Sort
                        keyValue="updated_at"
                        @valueChanges="sortHandler($event)"
                        :currentSort="currentSort == 'updated_at'"
                      />
                    </div>
                  </th>
                  <th scope="col" class="relative customers-list__col"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in employeesData.data"
                  :key="item.id"
                  :class="index % 2 ? 'bg-white' : 'bg-gray-50'"
                >
                  <td
                    class="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-sm
                      font-medium
                      text-gray-900
                    "
                  >
                    {{ item.id }}
                  </td>
                  <td class="px-6 py-4 break-all text-sm text-gray-500">
                    {{ item.first_name }}
                  </td>
                  <td class="px-6 py-4 break-all text-sm text-gray-500">
                    {{ item.last_name }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ item.email || 'No email' }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ item.phone_prefix }}
                    {{ item.phone_number || 'No phone number' }}
                  </td>
                  <td
                    class="
                      px-6
                      py-4
                      whitespace-normal
                      text-sm text-gray-500
                      space-y-1
                    "
                  >
                    <span
                      v-for="(role, i) in item.roles"
                      :key="i"
                      class="
                        inline-flex
                        items-center
                        px-2
                        py-0.5
                        rounded
                        text-xs
                        font-medium
                        bg-blue-100
                        text-blue-800
                        mr-1
                      "
                    >
                      {{ role.description }}
                    </span>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{
                      $dayjs
                        .utc(item.created_at)
                        .local()
                        .format('DD/MM/YYYY  HH:mm')
                    }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{
                      $dayjs
                        .utc(item.updated_at)
                        .local()
                        .format('DD/MM/YYYY  HH:mm')
                    }}
                  </td>

                  <td
                    class="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-center text-sm text-gray-500
                    "
                  >
                    <div class="flex space-x-6 justify-end">
                      <router-link
                        :to="{
                          name: 'EmployeesAddEdit',
                          params: { id: item.id },
                        }"
                        class="text-indigo-600 hover:text-indigo-900"
                      >
                        <DynamicIconSolid
                          icon="PencilIcon"
                          class="h-5 w-5 text-gray-400 m-auto cursor-pointer"
                          aria-hidden="true"
                        />
                      </router-link>
                      <DynamicIconSolid
                        icon="UserRemoveIcon"
                        class="h-5 w-5 text-gray-400 m-auto cursor-pointer"
                        aria-hidden="true"
                        @click="removeEmployee(item)"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- PAGINATION -->
    <Pagination @changePage="changePage($event)" :meta="employeesData.meta" />

    <!-- DELETE EMPLOYEE -->
    <EmployeesDelete
      :modalOpen="removeUserModal"
      :userData="userToRemove"
      :urlParams="config"
      @closeDeleteChanges="removeUserModal = false"
    />
  </div>
</template>

<script>
import { computed, reactive, ref, watch, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import Pagination from '@/components/@globals/helpers/Pagination';
import LoadingSpinner from '@/components/@globals/helpers/LoadingSpinner';
import Sort from '@/components/table/Sort';
import EmployeesDelete from './EmployeesDelete.vue';
import { TRichSelect } from '@variantjs/vue';
import Select from '@/components/select/Select';
import Label from '../../../components/@globals/helpers/Label';
import SecondaryButton from '../../../components/@globals/helpers/SecondaryButton';
import Button from '../../../components/@globals/helpers/Button';

export default {
  name: 'EmployeesList',

  components: {
    SecondaryButton,
    Pagination,
    LoadingSpinner,
    Sort,
    EmployeesDelete,
    TRichSelect,
    Select,
    Label,
    Button,
  },

  setup() {
    const instance = getCurrentInstance();
    const $dayjs = instance.appContext.config.globalProperties.$dayjs;
    const range = ref({
      start: null,
      end: null,
    });
    const currentSort = ref('');
    const store = useStore();
    let config = reactive({
      page: 1,
      per_page: 5,
      silent: false,
    });
    const itemsPerPageArray = [
      { id: 1, value: 5 },
      { id: 2, value: 10 },
      { id: 3, value: 15 },
      { id: 4, value: 20 },
    ];
    const rolesFilter = ref([]);

    store.dispatch('getEmployees', config);
    const employeesData = computed(() => store.getters.getEmployees);

    store.dispatch('getRoles', {});
    const rolesList = computed(() => store.getters.getRoles);

    function refreshData() {
      resetRange();
      store.dispatch('getEmployees', {
        page: 1,
        per_page: 5,
      });
    }

    function changePage(page) {
      config.page = page;
      store.dispatch('getEmployees', config);
    }

    function perPageHandler(payload) {
      config.per_page = payload;
      store.dispatch('getEmployees', config);
    }

    function rolesHandler(payload) {
      if (payload.length) {
        config = { ...config, roles: payload };
      } else {
        delete config.roles;
      }
      store.dispatch('getEmployees', config);
    }

    watch(
      () => range.value,
      (currentValue) => {
        if (currentValue.start != null && currentValue.end != null) {
          config = {
            ...config,
            created_at_from:
              $dayjs(currentValue.start).format('YYYY-MM-DD') + ' 00:00:00',
            created_at_to:
              $dayjs(currentValue.end).format('YYYY-MM-DD') + ' 23:59:59',
          };
        } else {
          delete config.created_at_from;
          delete config.created_at_to;
        }
        store.dispatch('getEmployees', config);
      },
      { deep: true }
    );

    watch(
      () => rolesFilter,
      (currentValue) => {
        rolesHandler(currentValue.value);
      },
      { deep: true }
    );

    function resetRange() {
      range.value.start = null;
      range.value.end = null;
    }

    function sortHandler(payload) {
      currentSort.value = payload.key;
      if (payload.sort != null) {
        config = { ...config, sort: payload.sort };
      } else {
        delete config.sort;
      }
      store.dispatch('getEmployees', config);
    }

    const removeUserModal = ref(false);
    const userToRemove = ref(null);
    function removeEmployee(item) {
      userToRemove.value = item;
      removeUserModal.value = true;
    }

    return {
      employeesData,
      currentSort,
      removeUserModal,
      userToRemove,
      changePage,
      sortHandler,
      removeEmployee,
      config,
      refreshData,
      rolesHandler,
      rolesList,
      rolesFilter,
      range,
      itemsPerPageArray,
      perPageHandler,
    };
  },
};
</script>
