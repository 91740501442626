<template>
  <nav
    v-if="meta.links"
    class="
      mt-10
      border-t border-gray-200
      px-4
      flex
      items-center
      justify-between
      sm:px-0
    "
  >
    <!-- PREV -->
    <div class="-mt-px w-0 flex-1 flex">
      <a
        :href="meta.links[0].url ? meta.links[0].url : '#'"
        :class="!meta.links[0].url ? ' opacity-50 cursor-not-allowed' : null"
        class="pagination__nav-btn"
        @click.prevent="changePage(meta.links[0].url.split('=')[1])"
      >
        <DynamicIconSolid
          icon="ArrowNarrowLeftIcon"
          class="flex-shrink-0 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
        Previous
      </a>
    </div>
    <!-- PAGINATION -->
    <div class="hidden md:-mt-px md:flex">
      <a
        v-for="link in pureNavItems"
        :key="link.url"
        :href="link.url"
        class="
          border-transparent
          text-gray-500
          hover:text-gray-700 hover:border-gray-300
          border-t-2
          pt-4
          px-4
          inline-flex
          items-center
          text-sm
          font-medium
        "
        :class="
          link.active
            ? 'border-indigo-500 text-indigo-600 hover:border-indigo-500 hover:text-indigo-600'
            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
        "
        @click.prevent="changePage(link.label)"
      >
        {{ link.label }}
      </a>

      <!-- <span
        class="
          border-transparent
          text-gray-500
          border-t-2
          pt-4
          px-4
          inline-flex
          items-center
          text-sm
          font-medium
        "
      >
        ...
      </span> -->
    </div>
    <!-- NEXT -->
    <div class="-mt-px w-0 flex-1 flex justify-end">
      <a
        :href="
          meta.links[meta.links.length - 1].url
            ? meta.links[meta.links.length - 1].url
            : '#'
        "
        :class="
          !meta.links[meta.links.length - 1].url
            ? ' opacity-50 cursor-not-allowed'
            : null
        "
        class="pagination__nav-btn"
        @click.prevent="
          changePage(meta.links[meta.links.length - 1].url.split('=')[1])
        "
      >
        Next
        <DynamicIconSolid
          icon="ArrowNarrowRightIcon"
          class="flex-shrink-0 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
      </a>
    </div>
  </nav>
</template>

<script>
import { ref, computed } from 'vue';
export default {
  name: 'Pagination',
  props: {
    meta: {
      type: Object,
      required: true,
    },
  },
  emits: ['changePage'],
  setup(props, context) {
    const currentPage = ref(props.meta.current_page);

    const pureNavItems = computed(() => {
      return props.meta.links.filter(
        (_, index) => index !== 0 && index !== props.meta.links.length - 1
      );
    });

    function changePage(page) {
      currentPage.value = page;
      context.emit('changePage', page);
    }
    return {
      currentPage,
      changePage,
      pureNavItems,
    };
  },
};
</script>
