<template>
  <div>
    <SectionHeading
      :title="$t('views.newsletters.newsletters.title')"
      iconName="NewspaperIcon"
      subTitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
    />

    <!-- ACTIONS -->
    <div class="mt-10 flex justify-start flex-wrap">
      <div class="justify-self-end ml-auto mr-0 flex align-center">
        <Select
          label="Per page"
          :dataList="itemsPerPageArray"
          @valueChanges="perPageHandler($event)"
        />
      </div>
    </div>
    <!-- NEWSLETTERS DATA -->
    <div class="flex flex-col sm:-mx-6 lg:-mx-8">
      <div class="my-2 overflow-x-auto">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="
              shadow
              overflow-hidden
              border-b border-gray-200
              sm:rounded-lg
              relative
            "
          >
            <LoadingSpinner v-if="newslettersData.loading" />
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="customers-list__col flex items-center">
                    <div class="flex items-center">
                      <span>ID</span>
                      <Sort
                        keyValue="id"
                        @valueChanges="sortHandler($event)"
                        :currentSort="currentSort == 'id'"
                      />
                    </div>
                  </th>
                  <th scope="col" class="customers-list__col">
                    <div class="flex items-center">
                      <span>Nazwa</span>
                      <Sort
                        keyValue="name"
                        @valueChanges="sortHandler($event)"
                        :currentSort="currentSort == 'name'"
                      />
                    </div>
                  </th>
                  <th scope="col" class="customers-list__col">
                    <div class="flex items-center">
                      <span>Opis</span>
                      <Sort
                        keyValue="description"
                        @valueChanges="sortHandler($event)"
                        :currentSort="currentSort == 'description'"
                      />
                    </div>
                  </th>
                  <th scope="col" class="customers-list__col">
                    <div class="flex items-center">
                      <span>Data utworzenia</span>
                      <Sort
                        keyValue="created_at"
                        @valueChanges="sortHandler($event)"
                        :currentSort="currentSort == 'created_at'"
                      />
                    </div>
                  </th>
                  <th scope="col" class="customers-list__col">
                    <div class="flex items-center">
                      <span>Data modyfikacji</span>
                      <Sort
                        keyValue="updated_at"
                        @valueChanges="sortHandler($event)"
                        :currentSort="currentSort == 'updated_at'"
                      />
                    </div>
                  </th>
                  <th scope="col" class="relative customers-list__col"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in newslettersData.data"
                  :key="item.id"
                  :class="index % 2 ? 'bg-white' : 'bg-gray-50'"
                >
                  <td
                    class="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-sm
                      font-medium
                      text-gray-900
                    "
                  >
                    {{ item.id }}
                  </td>
                  <td class="px-6 py-4 break-all text-sm text-gray-500">
                    {{ item.name }}
                  </td>
                  <td class="px-6 py-4 whitespace-normal text-sm text-gray-500">
                    {{ item.description }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{
                      dayjs
                        .utc(item.created_at)
                        .local()
                        .format('DD/MM/YYYY  HH:mm')
                    }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{
                      dayjs
                        .utc(item.updated_at)
                        .local()
                        .format('DD/MM/YYYY  HH:mm')
                    }}
                  </td>
                  <td
                    class="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-center text-sm
                      font-medium
                    "
                  >
                    <div class="flex space-x-6 justify-end">
                      <router-link
                        :to="{
                          name: 'NewslettersAddEdit',
                          params: { id: item.id },
                        }"
                        class="text-indigo-600 hover:text-indigo-900"
                      >
                        <DynamicIconSolid
                          icon="PencilIcon"
                          class="h-5 w-5 text-gray-400 m-auto cursor-pointer"
                          aria-hidden="true"
                        />
                      </router-link>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- PAGINATION -->
    <Pagination @changePage="changePage($event)" :meta="newslettersData.meta" />
  </div>
</template>

<script>
import Select from '@/components/select/Select';
import Pagination from '@/components/@globals/helpers/Pagination';
import Sort from '@/components/table/Sort';
import LoadingSpinner from '@/components/@globals/helpers/LoadingSpinner';
import { useStore } from 'vuex';
import { computed, reactive, ref, getCurrentInstance } from 'vue';
export default {
  name: 'NewslettersList',
  components: {
    Pagination,
    LoadingSpinner,
    Select,
    Sort,
  },
  setup() {
    const instance = getCurrentInstance();
    const dayjs = instance.appContext.config.globalProperties.$dayjs;
    const currentSort = ref('');
    const store = useStore();
    let config = reactive({
      page: 1,
      per_page: 5,
      silent: false,
    });
    const itemsPerPageArray = [
      { id: 1, value: 5 },
      { id: 2, value: 10 },
      { id: 3, value: 15 },
      { id: 4, value: 20 },
    ];

    store.dispatch('getNewsletters', config);

    const newslettersData = computed(() => store.getters.getNewsletters);

    function refreshData() {
      store.dispatch('getNewsletters', {
        page: 1,
        per_page: 5,
      });
    }
    function changePage(page) {
      config.page = page;
      store.dispatch('getNewsletters', config);
    }
    function perPageHandler(payload) {
      config.per_page = payload;
      store.dispatch('getNewsletters', config);
    }

    function sortHandler(payload) {
      currentSort.value = payload.key;
      if (payload.sort != null) {
        config = { ...config, sort: payload.sort };
      } else {
        delete config.sort;
      }
      store.dispatch('getNewsletters', config);
    }

    return {
      newslettersData,
      itemsPerPageArray,
      currentSort,
      refreshData,
      changePage,
      perPageHandler,
      sortHandler,
      dayjs,
    };
  },
};
</script>
