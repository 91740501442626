<template>
  <div>
    <SectionHeading
      :title="$t('views.dashboard.title')"
      iconName="HomeIcon"
      subTitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
    />
    <button
      class="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded-full"
      @click="testGet()"
    >
      TEST GET
    </button>
    <button
      class="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded-full"
      @click="refresh()"
    >
      Refresh Test
    </button>
    <button
      class="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded-full"
      @click="addNoti()"
    >
      Add Notification
    </button>
  </div>
</template>

<script>
import axios from 'axios';
import { useStore } from 'vuex';

export default {
  name: 'Dashboard',

  setup() {
    const store = useStore();

    function refresh() {
      store.dispatch('refreshToken');
    }
    function testGet() {
      axios.get('panel/api/v1/employees').then((response) => {
        console.log(response.data);
      });
    }
    function addNoti() {
      store.commit('setAppNotifications', {
        title: 'Nowa notyfiakcja!',
        content: 'Lorem dawid ipsum',
        type: 'success',
        autoclose: true,
      });
    }
    return { testGet, refresh, addNoti };
  },
};
</script>
