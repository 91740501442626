<template>
  <ConfirmationModal :show="toggleModal" @close="closeDeleteHandler()">
    <template #title>Potwierdź </template>

    <template #content>
      Czy na pewno chcesz usunąć użytkownika<br />
      <strong>{{ userData.first_name }} {{ userData.last_name }}</strong
      >?
    </template>

    <template #footer>
      <SecondaryButton @click="closeDeleteHandler()"> Anuluj </SecondaryButton>

      <DangerButton class="ml-2" @click="removeEmployeeAction()">
        Usuń
      </DangerButton>
    </template>
  </ConfirmationModal>
</template>

<script>
import ConfirmationModal from '../../../components/@globals/helpers/ConfirmationModal';
import SecondaryButton from '../../../components/@globals/helpers/SecondaryButton';
import DangerButton from '../../../components/@globals/helpers/DangerButton';
import EmployeesService from '@/service/employees/employees.js';
import { ref, watch } from 'vue';
import { useStore } from 'vuex';
export default {
  name: 'EmployeesDelete',
  props: {
    userData: Object,
    urlParams: Object,
    modalOpen: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ConfirmationModal,
    SecondaryButton,
    DangerButton,
  },
  emits: ['closeDeleteChanges'],
  setup(props, { emit }) {
    const store = useStore();
    const toggleModal = ref(props.modalOpen);

    watch(
      () => props.modalOpen,
      (val) => {
        toggleModal.value = val;
      }
    );

    function closeDeleteHandler() {
      toggleModal.value = false;
      emit('closeDeleteChanges', false);
    }

    function removeEmployeeAction() {
      EmployeesService.deleteEmployee(props.userData.id).then(
        () => {
          let silentConfig = { ...props.urlParams, silent: true };
          store.dispatch('getEmployees', silentConfig);
          store.commit('setAppNotifications', {
            title: 'Uzytkownik usunięty',
            content: 'Pomyslnie usunieto uzytkownika',
            type: 'success',
            autoclose: true,
          });
          closeDeleteHandler();
        },
        () => {
          store.commit('setAppNotifications', {
            title: 'Nie udało się usunąć uytkownika',
            content: '',
            type: 'error',
            autoclose: true,
          });
          closeDeleteHandler();
        }
      );
    }

    return {
      toggleModal,
      removeEmployeeAction,
      closeDeleteHandler,
    };
  },
};
</script>

<style></style>
