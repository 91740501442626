<template>
  <Listbox as="div" class="flex items-center" v-if="dataList.length != 0">
    <ListboxLabel
      v-if="label"
      class="block text-sm font-medium text-gray-700 mr-2"
    >
      {{ label }}
    </ListboxLabel>
    <div class="relative" :style="{ width: width }">
      <ListboxButton
        :style="{ width: width }"
        class="
          relative
          w-full
          bg-white
          border border-gray-300
          rounded-md
          shadow-sm
          pl-3
          pr-10
          py-2.5
          text-left
          cursor-default
          focus:outline-none
          sm:text-sm
        "
        :class="{
          'border-red-700 ': invalid,
        }"
      >
        <span class="block truncate">
          {{ choosen || placeholder }}
        </span>
        <span
          class="
            absolute
            inset-y-0
            right-0
            flex
            items-center
            pr-2
            pointer-events-none
          "
        >
          <DynamicIconSolid
            icon="SelectorIcon"
            class="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          v-if="!disabled"
          class="
            absolute
            z-10
            mt-1
            w-full
            bg-white
            shadow-lg
            max-h-60
            rounded-md
            py-1
            text-base
            ring-1 ring-black ring-opacity-5
            overflow-auto
            focus:outline-none
            sm:text-sm
          "
        >
          <ListboxOption
            as="template"
            v-for="item in dataList"
            :key="item.id"
            :value="item"
            v-slot="{ active, selected }"
            @click="pickedItem(item)"
          >
            <li
              :class="[
                active ? 'text-white bg-indigo-600' : 'text-gray-900',
                'cursor-default select-none relative py-2 pl-4 pr-4',
              ]"
            >
              <span
                :class="[
                  selected ? 'font-semibold' : 'font-normal',
                  'block truncate',
                ]"
              >
                {{ item.label ? item.label : item.value }}
              </span>

              <span
                v-if="selected"
                :class="[
                  active ? 'text-white' : 'text-indigo-600',
                  'absolute inset-y-0 left-0 flex items-center pl-1.5',
                ]"
              >
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script>
import { ref, watch } from 'vue';
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/vue';

export default {
  name: 'Select',
  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
  },
  props: {
    dataList: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Choose',
    },
    label: {
      type: String,
    },
    width: {
      type: String,
      default: 'auto',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    defaultValue: {
      type: String,
      default: null,
    },
  },
  emits: ['valueChanges'],
  setup(props, context) {
    const selected = ref(props.dataList[0]);
    const choosen = ref('');
    watch(
      () => props.defaultValue,
      (val) => {
        if (val != null) {
          choosen.value = val;
        }
      }
    );
    function pickedItem(item) {
      selected.value = item;
      selected.value.label
        ? (choosen.value = selected.value.label)
        : (choosen.value = selected.value.value);
      context.emit('valueChanges', item.value);
    }

    return {
      selected,
      pickedItem,
      choosen,
    };
  },
};
</script>
